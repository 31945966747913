.timeslot-item {
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.checkbox {
    margin: 4px;
    min-width: 14px;
}

.timeslot-range {
    margin: 4px;
    min-width: 154px;
}
