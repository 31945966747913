
.app-page {
    padding: 1.5rem;
}

.app-div {
    height: 100%;
}

.vt-card-vertical-list {
    margin-bottom: 1.25rem;
}

.centered-spinner-div {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
}

.row-form-div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
}

.success-alert {
    margin: 1rem;
}
