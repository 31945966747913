.day-view {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
}

.form-select.timeslot-size-picker {
    width: auto;
    margin: 4px;
}

.day-view-button {
    margin: 4px;
}
