.main-controls {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.schedule-page{
    padding: 12px;
}

.vt-submit-timeslots-button {
    margin-top: 8px;
}

.service-type-select {
    margin-bottom: 1rem;
}
