.home-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-card {
    width: 80%;
}

.input {
    margin-bottom: 0.5rem;
}
